import React from 'react'
import Layout from '../components/layout'
import GameDeveloper from '../components/categories/game-developer'
import Seo from '../components/seo'

const GameDeveloperPage = ({ location }) => {
  return (
    <Layout location={location}>
      <GameDeveloper />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Game Developer Freelancers | Codestaff'
  const description =
    'Hire the best Game Developer freelancers at Codestaff. Get the top 1% of Game Developer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default GameDeveloperPage
