import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import styled from 'styled-components'
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "How Important Are Games?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "The popularity of smart gadgets such as tablets, smartphones, etc has opened up a new channel for entertainment in the software industry, which is the mobile gaming domain. Today, the games market is a huge part of the mobile ecosystem which is worth over $$15 billion."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Will I Get Revenue From Games?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "As soon as your game is live, it will start to generate revenue and profits by selling your games (in-app purchases and paid games). If your game is free to play, you can monetize it. Codestaff Game Developers can develop highly profitable games for you."
        }
    }
}

const productMeta = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "Freelance Game Developer",
    "image": "https://codestaff.io/game-developer.png",
    "description": "Freelance Game Developers at Code Staff have all of the skill necessary to help your next project succeed. Hire a Remote Game Developer and have them join your team.",
    "brand": "Code Staff",
    "offers": {
        "@type": "Offer",
        "url": "https://codestaff.io/game-developer",
        "priceCurrency": "USD",
        "price": "99",
        "priceValidUntil": "2020-05-27",
        "availability": "https://schema.org/OnlineOnly",
        "itemCondition": "https://schema.org/NewCondition"
    },
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "1",
        "reviewCount": "1"
    },
    "review": {
        "@type": "Review",
        "name": "Los Angeles Laundry",
        "reviewBody": "Always does a great job and his turn around time is impeccable!",
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1"
        },
        "datePublished": "2020-05-06",
        "author": { "@type": "Person", "name": "Los Angeles Laundry" },
        "publisher": { "@type": "Organization", "name": "Freelancer Website" }
    }
}

const MainWrapper = styled.div`
margin: 0;
`

const GameDeveloper = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
            <script type="application/ld+json">{JSON.stringify(productMeta)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Game Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Game Developers. Top
                                    companies and start-ups choose Codestaff professional Game
                                    Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Game Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE GAME DEVELOPERS' banner='/game-developer.png' bannerAlt='game developer banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default GameDeveloper